import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import './PasswordReset.css';

function PasswordReset() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const uidb64 = searchParams.get('uidb64');
    const token = searchParams.get('token');

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setMessage('Las contraseñas no coinciden');
            return;
        }
        try {
            // Enviar el token en el cuerpo de la solicitud, junto con la nueva contraseña
            await axiosInstance.post(`/password-reset/`, {
                password: newPassword,
                token: token,
            }, {
                params: {
                    uidb64: uidb64
                }
            });
            setMessage('Tu contraseña ha sido restablecida exitosamente.');
            setTimeout(() => {
                navigate('/login'); // Redirige al usuario al inicio de sesión después de un tiempo
            }, 3000);
        } catch (error) {
            setMessage('Hubo un problema al intentar restablecer la contraseña.');
        }
    };

    return (
        <div className="password-reset-container">
            <h2>Establecer Nueva Contraseña</h2>
            <form onSubmit={handlePasswordReset}>
                <input
                    type="password"
                    placeholder="Nueva Contraseña"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Confirmar Nueva Contraseña"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <button type="submit">Restablecer Contraseña</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default PasswordReset;