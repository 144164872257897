import React, { useState, useEffect } from 'react';
import './ResumenEstadistico.css';
import Header from "./Header";
import { DataGrid } from '@mui/x-data-grid';
import { TextField, MenuItem, Select, FormControl, InputLabel, Button, Box } from '@mui/material';
import axiosInstance from '../services/axiosConfig';
import { CSVLink } from "react-csv";

const ResumenEstadistico = () => {
    const [procedimientos, setProcedimientos] = useState([]);
    const [filteredProcedimientos, setFilteredProcedimientos] = useState([]);
    const [filters, setFilters] = useState({
        fechaCreacionDesde: '',
        fechaCreacionHasta: '',
        fechaAsignacionDesde: '',
        fechaAsignacionHasta: '',
        fechaEntregaDesde: '',
        fechaEntregaHasta: '',
        estado: '',
        juzgado: '',
        especialidad: ''
    });

    useEffect(() => {
        const fetchProcedimientos = async () => {
            try {
                const response = await axiosInstance.get('procedimientos/');
                setProcedimientos(response.data);
                setFilteredProcedimientos(response.data);
            } catch (error) {
                console.error('Error al cargar los procedimientos', error);
            }
        };

        fetchProcedimientos();
    }, []);

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const applyFilters = () => {
        let filteredProcedimientos = procedimientos;

        if (filters.fechaCreacionDesde) {
            filteredProcedimientos = filteredProcedimientos.filter(procedimiento =>
                new Date(procedimiento.fecha_creacion) >= new Date(filters.fechaCreacionDesde)
            );
        }
        if (filters.fechaCreacionHasta) {
            filteredProcedimientos = filteredProcedimientos.filter(procedimiento =>
                new Date(procedimiento.fecha_creacion) <= new Date(filters.fechaCreacionHasta)
            );
        }
        if (filters.fechaAsignacionDesde) {
            filteredProcedimientos = filteredProcedimientos.filter(procedimiento =>
                new Date(procedimiento.fecha_asignacion) >= new Date(filters.fechaAsignacionDesde)
            );
        }
        if (filters.fechaAsignacionHasta) {
            filteredProcedimientos = filteredProcedimientos.filter(procedimiento =>
                new Date(procedimiento.fecha_asignacion) <= new Date(filters.fechaAsignacionHasta)
            );
        }
        if (filters.fechaEntregaDesde) {
            filteredProcedimientos = filteredProcedimientos.filter(procedimiento =>
                new Date(procedimiento.fecha_entrega_informe) >= new Date(filters.fechaEntregaDesde)
            );
        }
        if (filters.fechaEntregaHasta) {
            filteredProcedimientos = filteredProcedimientos.filter(procedimiento =>
                new Date(procedimiento.fecha_entrega_informe) <= new Date(filters.fechaEntregaHasta)
            );
        }
        if (filters.estado) {
            filteredProcedimientos = filteredProcedimientos.filter(procedimiento =>
                procedimiento.estado === filters.estado
            );
        }
        if (filters.juzgado) {
            filteredProcedimientos = filteredProcedimientos.filter(procedimiento =>
                procedimiento.juzgado_nombre.toLowerCase().includes(filters.juzgado.toLowerCase())
            );
        }
        if (filters.especialidad) {
            filteredProcedimientos = filteredProcedimientos.filter(procedimiento =>
                procedimiento.especialidad_nombre.toLowerCase().includes(filters.especialidad.toLowerCase())
            );
        }

        setFilteredProcedimientos(filteredProcedimientos);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'estado', headerName: 'Estado', width: 150 },
        { field: 'especialidad_nombre', headerName: 'Especialidad', width: 150 },
        { field: 'juzgado_nombre', headerName: 'Juzgado', width: 150 },
        { field: 'numero_procedimiento', headerName: 'Nº Procedimiento', width: 200 },
        { field: 'fecha_creacion', headerName: 'Fecha de Creación', width: 180 },
        { field: 'fecha_asignacion', headerName: 'Fecha de Asignación', width: 180 },
        { field: 'fecha_entrega_informe', headerName: 'Fecha de Entrega del Informe', width: 210 },
    ];

    const csvHeaders = columns.map(column => ({ label: column.headerName, key: column.field }));

    return (
        <div className="resumen-container">
            <Header /> {/* Reemplaza el código del encabezado con el componente Header */}

            <div className="resumen-content">
                <h2>Resumen Estadístico</h2>

                <Box sx={{ marginBottom: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }} className="resumen-filters">
                    {/* Filtros de fechas */}
                    <TextField
                        label="Fecha Creación Desde"
                        type="date"
                        name="fechaCreacionDesde"
                        value={filters.fechaCreacionDesde}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Creación Hasta"
                        type="date"
                        name="fechaCreacionHasta"
                        value={filters.fechaCreacionHasta}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Asignación Desde"
                        type="date"
                        name="fechaAsignacionDesde"
                        value={filters.fechaAsignacionDesde}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Asignación Hasta"
                        type="date"
                        name="fechaAsignacionHasta"
                        value={filters.fechaAsignacionHasta}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Entrega Desde"
                        type="date"
                        name="fechaEntregaDesde"
                        value={filters.fechaEntregaDesde}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Fecha Entrega Hasta"
                        type="date"
                        name="fechaEntregaHasta"
                        value={filters.fechaEntregaHasta}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="resumen-filter-item"
                    />
                    {/* Filtros por estado, juzgado y especialidad */}
                    <FormControl sx={{ minWidth: 120 }} className="resumen-filter-item">
                        <InputLabel>Estado</InputLabel>
                        <Select
                            name="estado"
                            value={filters.estado}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value="solicitado">Solicitado</MenuItem>
                            <MenuItem value="asignado_peritos">Asignado Peritos</MenuItem>
                            <MenuItem value="informe_entregado">Informe Entregado</MenuItem>
                            <MenuItem value="informe_descargado">Informe Descargado</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Juzgado"
                        name="juzgado"
                        value={filters.juzgado}
                        onChange={handleFilterChange}
                        className="resumen-filter-item"
                    />
                    <TextField
                        label="Especialidad"
                        name="especialidad"
                        value={filters.especialidad}
                        onChange={handleFilterChange}
                        className="resumen-filter-item"
                    />
                    {/* Botones de acción */}
                    <Button variant="contained" onClick={applyFilters} className="resumen-filter-apply">Aplicar Filtros</Button>
                    <CSVLink
                        data={filteredProcedimientos}
                        headers={csvHeaders}
                        filename={"resumen_estadistico.csv"}
                        className="resumen-filter-apply resumen-export-csv"
                    >
                        Exportar CSV
                    </CSVLink>
                </Box>

                <div style={{ height: 400, width: '100%' }} className="resumen-grid-container">
                    <DataGrid
                        rows={filteredProcedimientos}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        disableColumnMenu
                    />
                </div>
            </div>
        </div>
    );
};

export default ResumenEstadistico;