import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Platform from './components/Platform';
import ResumenEstadistico from './components/ResumenEstadistico';
import MiPerfil from './components/MiPerfil';
import PrivateRoute from './components/PrivateRoute';
import './App.css';
import PasswordResetRequest from './components/PasswordResetRequest'; // Importa el componente de solicitud de restablecimiento
import PasswordReset from './components/PasswordReset'; // Importa el componente de restablecimiento

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route
                    path="/plataforma"
                    element={
                        <PrivateRoute>
                            <Platform />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/resumen-estadistico"
                    element={
                        <PrivateRoute>
                            <ResumenEstadistico />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/mi-perfil"
                    element={
                        <PrivateRoute>
                            <MiPerfil />
                        </PrivateRoute>
                    }
                />
                <Route path="/password-reset-request" element={<PasswordResetRequest />} />
                <Route path="/password-reset" element={<PasswordReset />} />
            </Routes>
        </Router>
    );
}

export default App;