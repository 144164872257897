import React, { useState } from 'react';
import axiosInstance from '../services/axiosConfig';
import './PasswordReset.css';

function PasswordResetRequest() {
    const [correo_electronico, setCorreoElectronico] = useState('');
    const [message, setMessage] = useState('');

    const handlePasswordResetRequest = async (event) => {
        event.preventDefault();
        try {
            await axiosInstance.post('/password-reset-request/', { email: correo_electronico });
            setMessage('Si existe una cuenta con este correo, recibirás un enlace para restablecer tu contraseña.');
        } catch (error) {
            setMessage('Hubo un problema al intentar enviar la solicitud de restablecimiento de contraseña.');
        }
    };

    return (
        <div className="password-reset-container">
            <h2>Restablecer Contraseña</h2>
            <form onSubmit={handlePasswordResetRequest}>
                <input
                    type="email"
                    placeholder="Correo electrónico"
                    value={correo_electronico}
                    onChange={(e) => setCorreoElectronico(e.target.value)}
                    required
                />
                <button type="submit">Enviar Solicitud de Restablecimiento</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default PasswordResetRequest;