import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';
import './Plataforma.css';
import Header from '../components/Header';
import NuevoProcedimientoPopup from './NuevoProcedimientoPopup';
import EditarProcedimientoPopup from './EditarProcedimientoPopup';
import EditarNotasPopup from './EditarNotasPopup';
import EditarPeritosPopup from './EditarPeritosPopup';



// Mapeo para los nombres amigables de los estados
const estadoMap = {
    solicitado: 'Solicitado',
    asignado_peritos: 'Asignado Peritos',
    informe_entregado: 'Informe Entregado',
    pendiente_descarga: 'Pendiente Descarga',
    informe_descargado: 'Informe Descargado'
};

// Constante para formatear la fecha

const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
};

//Constante que devuelve el icono correspondiente a la extensión del archivo
const getIconForExtension = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
        case 'pdf':
            return require('../assets/icons/pdf-icon.svg').default;
        case 'doc':
        case 'docx':
            return require('../assets/icons/doc-icon.svg').default;
        default:
            return require('../assets/icons/default-icon.svg').default;
    }
};

// Componente principal de la plataforma
const Platform = () => {
    const [procedimientos, setProcedimientos] = useState([]);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProcedimiento, setSelectedProcedimiento] = useState(null);
    const [documentos, setDocumentos] = useState([]);
    const [uploadError, setUploadError] = useState('');
    const [showModal, setShowModal] = useState(false); // Estado para mostrar/ocultar el popup
    const [isNotasPopupVisible, setIsNotasPopupVisible] = useState(false);
    const [isPeritosPopupVisible, setIsPeritosPopupVisible] = useState(false);
    const tieneInformes = documentos.some(doc => doc.es_informe);
    const [isFilterActive, setIsFilterActive] = useState(false); // Nuevo estado para manejar el filtro
    const [hasInformeEntregado, setHasInformeEntregado] = useState(false);
    const baseUrl = 'https://api.afperitaciones.es';

    useEffect(() => {
        const fetchProcedimientos = async () => {
            try {
                const response = await axiosInstance.get('procedimientos/', {
                    params: {
                        search: searchTerm,
                        limit: 50
                    }
                });
                if (response.data.length === 0) {
                    setError('No hay procedimientos judiciales disponibles o coincidentes.');
                    setProcedimientos([]);
                    setHasInformeEntregado(false); // No hay procedimientos, así que ocultamos el ícono
                } else {
                    setError('');
                    const sortedProcedimientos = response.data.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));

                    const filteredProcedimientos = isFilterActive
                        ? sortedProcedimientos.filter(p => p.estado === 'informe_entregado')
                        : sortedProcedimientos;

                    setProcedimientos(filteredProcedimientos.slice(0, 50));

                    // Verifica si hay al menos un procedimiento en estado "informe_entregado"
                    const hasInforme = sortedProcedimientos.some(p => p.estado === 'informe_entregado');
                    setHasInformeEntregado(hasInforme);
                }
            } catch (error) {
                setError('Error al cargar los procedimientos');
                console.error(error);
            }
        };

        fetchProcedimientos();
    }, [searchTerm, isFilterActive]);

    useEffect(() => {
        const selectedId = localStorage.getItem('selectedProcedimiento');
        if (selectedId) {
            const selected = procedimientos.find(p => p.id === parseInt(selectedId, 10));
            if (selected) {
                setSelectedProcedimiento(selected);
            }
            localStorage.removeItem('selectedProcedimiento');
        } else if (procedimientos.length > 0) {
            setSelectedProcedimiento(procedimientos[0]); // Selecciona el primer procedimiento
        }
    }, [procedimientos]);

    useEffect(() => {
        if (selectedProcedimiento) {
            const fetchDocumentos = async () => {
                try {
                    const response = await axiosInstance.get(`procedimientos/${selectedProcedimiento.id}/documentos/`);
                    setDocumentos(response.data);
                } catch (error) {
                    console.error('Error al cargar los documentos:', error);
                }
            };

            fetchDocumentos();
        }
    }, [selectedProcedimiento]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFilterClick = () => {
        setIsFilterActive(prevState => !prevState); // Activa o desactiva el filtro
    };

    const handleSelectProcedimiento = (procedimiento) => {
        setSelectedProcedimiento(procedimiento);
        setUploadError('');
    };

    const handleFileUpload = async (event, esInforme = false, esCitacion = false) => {
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append('documento', files[i]);
            formData.append('es_informe', esInforme.toString());
            formData.append('es_citacion', esCitacion.toString());// Agrega este campo

            try {
                await axiosInstance.post(`procedimientos/${selectedProcedimiento.id}/documentos/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } catch (error) {
                setUploadError('Error al subir los archivos');
                console.error('Error al subir los archivos:', error);
                return;
            }
        }

        try {
            const response = await axiosInstance.get(`procedimientos/${selectedProcedimiento.id}/documentos/`);
            setDocumentos(response.data);
            setUploadError('');
        } catch (error) {
            setUploadError('Error al actualizar la lista de documentos');
            console.error('Error al actualizar la lista de documentos:', error);
        }

        try {
            const response = await axiosInstance.get(`procedimientos/${selectedProcedimiento.id}/`);
            setSelectedProcedimiento(response.data);
        } catch (error) {
            console.error('Error al actualizar el estado del procedimiento:', error);
        }
    };

    const handleDrop = (event, esInforme = false, esCitacion = false) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        handleFileUpload({ target: { files } }, esInforme, esCitacion);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };


    const handleAddClick = () => {
        setShowModal(true); // Mostrar el popup
    };

    const handleModalClose = () => {
        setShowModal(false); // Cerrar el popup
    };

    const handleSubmitNuevoProcedimiento = (nuevoProcedimiento) => {
        setProcedimientos([...procedimientos, nuevoProcedimiento]);
        setShowModal(false); // Cerrar el popup después de añadir el procedimiento
    };


    const handleDownloadAll = async () => {
        try {
            const response = await axiosInstance.get(`/procedimientos/${selectedProcedimiento.id}/descargar_todos_los_documentos/`, {
                responseType: 'blob', // Para manejar el archivo binario
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `documentos_procedimiento_${selectedProcedimiento.id}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error al descargar los documentos:', error);
        }
    };

    const handleDownloadAllInformes = async () => {
        try {
            const response = await axiosInstance.get(`/procedimientos/${selectedProcedimiento.id}/descargar_informes/`, {
                responseType: 'blob', // Manejo de archivo binario
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `informes_procedimiento_${selectedProcedimiento.id}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            await axiosInstance.post(`/procedimientos/${selectedProcedimiento.id}/marcar_informe_descargado/`);
        } catch (error) {
            console.error('Error al descargar los informes o actualizar el estado:', error);
        }
    };

//COMPONENTE EDITAR PROCEDIMIENTO
    const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
    const handleEditClick = () => {
        setIsEditPopupVisible(true);
    };

    const handleNotasClick = () => {
        setIsNotasPopupVisible(true);
    };

    const handleEditPeritosClick = () => {
        setIsPeritosPopupVisible(true);
    };

    //ROLES DE USUARIO

    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        axiosInstance.get('/mi_usuario/')
            .then(response => {
                console.log('Rol del usuario:', response.data.role); // Verifica qué valor tiene role
                setUserRole(response.data.role.trim()); // Asegúrate de que no haya espacios en blanco
            })
            .catch(error => {
                console.error('Error al obtener el rol del usuario:', error);
            });
    }, []);
    //FIN ROLES DE USUARIO

    return (
        <div className="plataforma-container">
            <Header /> {/* Reemplaza el código del encabezado con el componente Header */}
            <div className="plataforma-content">
                <div className="sidebar">
                    <div className="search-filters">
                        <input
                            type="text"
                            className="search-bar"
                            placeholder="Buscar por juzgado o N.º Procedimiento"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <div className="search-buttons">
                            <img src={require('../assets/icons/filter.svg').default} alt="Filter"
                                 className="filter-icon" />
                            {userRole !== 'Perito' && (
                                <img src={require('../assets/icons/add.svg').default} alt="Add" className="add-icon"
                                     onClick={handleAddClick}/>)}

                            {userRole !== 'Perito' && hasInformeEntregado && (
                                <img src={require('../assets/icons/download.svg').default} alt="Descargar Informes"
                                     className="filter-icon" onClick={handleFilterClick}/>)}

                        </div>
                    </div>
                    <div className="procedimientos-list">
                        {error && <p>{error}</p>}
                        <ul>
                            {procedimientos.map(procedimiento => (
                                <li
                                    key={procedimiento.id}
                                    onClick={() => handleSelectProcedimiento(procedimiento)}
                                    className={procedimiento.id === selectedProcedimiento?.id ? 'selected' : ''}
                                >
                                    <div className="procedimiento-caja">
                                        <span className="estado-procedimiento">
                                            {estadoMap[procedimiento.estado]}
                                        </span>
                                        <div className="procedimiento-info">
                                            <p><strong>{procedimiento.juzgado_nombre || 'N/A'}</strong></p>
                                            <p>Número de Procedimiento: {procedimiento.numero_procedimiento}</p>
                                            <p>Especialidad: {procedimiento.especialidad_nombre || 'N/A'}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="main-content">
                    {selectedProcedimiento ? (
                        <div className="detalle-container">
                            <div className="detalle-procedimiento">
                                {userRole !== 'Perito' && (<img
                                    src={require('../assets/icons/edit.svg').default}
                                    alt="Editar procedimiento"
                                    className="edit-icon-top-right"
                                    onClick={handleEditClick}
                                />)}
                                <img
                                    src={require('../assets/icons/chat.svg').default}
                                    alt="Editar notas"
                                    className="chat-icon-top-right"
                                    onClick={handleNotasClick}
                                />
                                <h2>Detalles del Procedimiento</h2>

                                <p className="juzgado">{selectedProcedimiento.juzgado_nombre || 'N/A'}</p>
                                <p className="numero-procedimiento">{selectedProcedimiento.numero_procedimiento || 'N/A'}</p>
                                <p className="detalle-estado-procedimiento">{estadoMap[selectedProcedimiento.estado] || 'N/A'} - {selectedProcedimiento.especialidad_nombre || 'N/A'}</p>
                                <p className="detalle-estado-fechas">Fechas Relevantes:</p>
                                <div className="fechas-procedimiento">
                                    <div className="fecha-item">
                                        <p className="fecha-titulo">Solicitado</p>
                                        <p>{selectedProcedimiento.fecha_creacion ? formatDate(selectedProcedimiento.fecha_creacion) : 'No disponible'}</p>
                                    </div>
                                    <div className="fecha-item">
                                        <p className="fecha-titulo">Asignado</p>
                                        <p>{selectedProcedimiento.fecha_asignacion ? formatDate(selectedProcedimiento.fecha_asignacion) : 'No asignado'}</p>
                                    </div>
                                    <div className="fecha-item">
                                        <p className="fecha-titulo">Aceptado</p>
                                        <p>{selectedProcedimiento.fecha_asignacion ? formatDate(selectedProcedimiento.fecha_asignacion) : 'No aceptado'}</p>
                                    </div>
                                    <div className="fecha-item">
                                        <p className="fecha-titulo">Entrega</p>
                                        <p>{selectedProcedimiento.fecha_entrega_informe ? formatDate(selectedProcedimiento.fecha_entrega_informe) : 'No entregado'}</p>
                                    </div>
                                </div>
                                <div className="detalles-otros">
                                    <div className="detalle-item detalle-item-control-calidad">
                                        <strong>Control de
                                            Calidad:</strong> {selectedProcedimiento.control_calidad || 'Pendiente'}
                                    </div>
                                    <div className="detalle-item detalle-item-ratificacion">
                                        <strong>Ratificación
                                            Solicitada:</strong> {selectedProcedimiento.ratificacion_solicitada === 'si' ? `Sí (${formatDate(selectedProcedimiento.fecha_ratificacion)})` : 'No'}
                                    </div>
                                    <div className="detalle-item detalle-item-incomparecencias">
                                        <strong>{selectedProcedimiento.especialidad_nombre === 'Peritación Excepcional'
                                            ? 'Presupuesto:'
                                            : 'Incomparecencias:'}</strong> {selectedProcedimiento.especialidad_nombre === 'Peritación Excepcional'
                                        ? 'Aprobado'
                                        : selectedProcedimiento.incomparecencias === 'si' ? 'Sí' : 'No'}
                                    </div>
                                    <div className="detalle-item">
                                        <strong>{selectedProcedimiento.especialidad_nombre === 'Peritación Excepcional'
                                            ? 'Gratuita:'
                                            : 'Miembros Evaluados:'}</strong> {selectedProcedimiento.especialidad_nombre === 'Peritación Excepcional'
                                        ? 'No'
                                        : selectedProcedimiento.numero_miembros_evaluados}
                                    </div>
                                    <div className="detalle-item">
                                        <strong>Informe
                                            Ratificado:</strong> {selectedProcedimiento.informe_ratificado === 'si' ? 'Sí' : 'No'}
                                    </div>
                                    <div className="detalle-item">
                                        <strong>Episodio
                                            IML:</strong> {selectedProcedimiento.numero_episodio_iml || 'No disponible'}
                                    </div>

                                </div>

                                <h2 className="peritos-titulo">
                                    Peritos Asignados
                                    {userRole === 'Admin' && (
                                        <img
                                            src={require('../assets/icons/edit_experts.svg').default}
                                            alt="Editar peritos asignados"
                                            className="peritos-icon-inline"
                                            onClick={handleEditPeritosClick}
                                        />
                                    )}
                                </h2>

                                <div className="peritos-asignados">
                                    {selectedProcedimiento.peritos_asignados.length > 0 ? (
                                        selectedProcedimiento.peritos_asignados.map((perito, index) => (
                                            <p key={index} className="perito-nombre">
                                                {`${perito.nombre} ${perito.apellidos} (${perito.especialidad_pericial?.nombre || 'Sin especialidad'})`}
                                            </p>
                                        ))
                                    ) : (
                                        <p>No hay peritos asignados.</p>
                                    )}
                                </div>

                            </div>

                            <div className="documentos-procedimiento">
                                <h3>Documentación</h3>
                                <img
                                    src={require('../assets/icons/download_all.svg').default}
                                    alt="Descargar todos los documentos"
                                    className="download-all-icon"
                                    onClick={handleDownloadAll}
                                />
                                {documentos.length === 0 ? (
                                    <p>No hay documentos subidos para este procedimiento.</p>
                                ) : (
                                    <ul className="documentos-list">
                                        {documentos.map((doc) => (
                                            <li key={doc.id}>
                                                <img src={getIconForExtension(doc.nombre)} alt="icon"
                                                     className="document-icon"/>
                                                {doc.es_informe && (
                                                    <img src={require('../assets/icons/es_informe.svg').default}
                                                         alt="Informe" className="document-icon-informe"/>
                                                )}
                                                <a href={`${baseUrl}${doc.archivo}`} download target="_blank"
                                                   rel="noopener noreferrer">
                                                    {doc.nombre} {doc.es_informe && '(INFORME)'}
                                                    {doc.es_citacion && ' (CITACIÓN)'}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                )}

                                {userRole !== 'Perito' && (<div className="upload-section">
                                    <h4>Subir Documentos</h4>
                                    <div
                                        className="dropzone"
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                        onClick={() => document.getElementById('fileInput').click()}>
                                        Arrastra y suelta archivos aquí o haz clic para subir
                                    </div>
                                    <input
                                        id="fileInput"
                                        type="file"
                                        onChange={handleFileUpload}
                                        style={{display: 'none'}}
                                        multiple
                                    />
                                    <input id="fileInput" type="file" onChange={handleFileUpload}
                                           style={{display: 'none'}}
                                           multiple
                                    />
                                    {uploadError && <p className="upload-error">{uploadError}</p>}
                                </div>)}

                                {(userRole === 'Perito' || userRole === 'Admin') && (<div className="upload-section">
                                    <h4>Subir Informe Pericial</h4>
                                    <div
                                        className="dropzone"
                                        onDrop={(event) => handleDrop(event, true)} // Drop para informes periciales
                                        onDragOver={handleDragOver}
                                        onClick={() => document.getElementById('fileInputInforme').click()}>
                                        Arrastra y suelta archivos aquí o haz clic para subir
                                    </div>
                                    <input
                                        id="fileInputInforme"
                                        type="file"
                                        onChange={(event) => handleFileUpload(event, true)} // Para informes periciales
                                        style={{display: 'none'}}
                                        multiple
                                    />
                                    {uploadError && <p className="upload-error">{uploadError}</p>}
                                </div>)}

                                {userRole !== 'Perito' && (
                                <div className="upload-section">
                                    <h4>Subir Citación</h4>
                                    <div
                                        className="dropzone"
                                        onDrop={(event) => handleDrop(event, false, true)} // Drop para citaciones
                                        onDragOver={handleDragOver}
                                        onClick={() => document.getElementById('fileInputCitacion').click()}>
                                        Arrastra y suelta archivos aquí o haz clic para subir
                                    </div>
                                    <input
                                        id="fileInputCitacion"
                                        type="file"
                                        onChange={(event) => handleFileUpload(event, false, true)} // Para citaciones
                                        style={{display: 'none'}}
                                        multiple
                                    />
                                    {uploadError && <p className="upload-error">{uploadError}</p>}
                                </div>)}

                                {tieneInformes && userRole !== 'Perito' && (
                                    <button className="download-informes-button" onClick={handleDownloadAllInformes}>
                                        Descargar Informe/s
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <p>Selecciona un procedimiento para ver los detalles.</p>
                    )}
                </div>
            </div>
            {showModal && (
                <NuevoProcedimientoPopup
                    onClose={handleModalClose}
                    onSubmit={handleSubmitNuevoProcedimiento}
                />
            )}
            {isEditPopupVisible && (
                <EditarProcedimientoPopup
                    onClose={() => setIsEditPopupVisible(false)}
                    procedimiento={selectedProcedimiento}
                    onUpdate={(updatedProcedimiento) => {
                        setSelectedProcedimiento(updatedProcedimiento);
                        setIsEditPopupVisible(false);
                    }}
                />
            )}

            {isNotasPopupVisible && (
                <EditarNotasPopup
                    onClose={() => setIsNotasPopupVisible(false)}
                    procedimiento={selectedProcedimiento}
                    onUpdate={(updatedProcedimiento) => {
                        setSelectedProcedimiento(updatedProcedimiento);
                        setIsNotasPopupVisible(false);
                    }}
                />
            )}

            {isPeritosPopupVisible && (
                <EditarPeritosPopup
                    onClose={() => setIsPeritosPopupVisible(false)}
                    procedimiento={selectedProcedimiento}
                    onUpdate={(updatedProcedimiento) => {
                        setSelectedProcedimiento(updatedProcedimiento);
                        setIsPeritosPopupVisible(false);
                    }}
                />
            )}

            {isPeritosPopupVisible && (
                <EditarPeritosPopup
                    onClose={() => setIsPeritosPopupVisible(false)}
                    procedimiento={selectedProcedimiento}
                    onUpdate={(updatedProcedimiento) => {
                        setSelectedProcedimiento(updatedProcedimiento);
                        setIsPeritosPopupVisible(false);
                    }}
                />
            )}
        </div>
    );
};

export default Platform;
