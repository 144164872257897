import React from 'react';
import './Plataforma.css';  // Reutilizamos los estilos de la plataforma

const MiPerfil = () => {
    return (
        <div className="plataforma-container">
            <header className="plataforma-header">
                <img src={require('../assets/images/logo.svg').default} alt="Company Logo" className="header-logo" />
                <div className="header-text">
                    <h1>AF Peritaciones</h1>
                    <p>Plataforma Integral de Gestión de Peritaciones Judiciales Forenses</p>
                </div>
            </header>
            <nav className="menu-horizontal">
                <a href="/plataforma">Procedimientos Judiciales</a>
                <a href="/resumen-estadistico">Resumen Estadístico</a>
                <a href="/mi-perfil">Mi Perfil</a>
                <a href="/" onClick={() => {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }}>Cerrar Sesión</a>
            </nav>
            <div className="plataforma-content">
                <h2>Mi Perfil</h2>
                <p>Aquí se mostrarán y permitirán editar los datos del usuario.</p>
                {/* Aquí puedes añadir un formulario para editar los datos del perfil */}
            </div>
        </div>
    );
};

export default MiPerfil;