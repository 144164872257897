import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';
import './EditarPeritosPopup.css';

const EditarPeritosPopup = ({ onClose, procedimiento, onUpdate }) => {
    const [availablePeritos, setAvailablePeritos] = useState([]);
    const [peritosSeleccionados, setPeritosSeleccionados] = useState(procedimiento.peritos_asignados || []);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchPeritos = async () => {
            try {
                const response = await axiosInstance.get('/usuarios/');
                const peritos = response.data.filter(usuario => usuario.especialidad_pericial);
                setAvailablePeritos(peritos);
            } catch (error) {
                console.error('Error al cargar los peritos:', error);
            }
        };
        fetchPeritos();
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleAddPerito = (perito) => {
        if (!peritosSeleccionados.some(p => p.id === perito.id)) {
            setPeritosSeleccionados([...peritosSeleccionados, perito]);
        }
    };

    const handleRemovePerito = (peritoId) => {
        setPeritosSeleccionados(peritosSeleccionados.filter(perito => perito.id !== peritoId));
    };

    const handleSave = async () => {
        try {
            const estado = peritosSeleccionados.length > 0 ? 'asignado_peritos' : procedimiento.estado;

            console.log('Enviando datos:', {
                peritos_asignados: peritosSeleccionados.map(perito => perito.id),
                estado: estado
            });

            const response = await axiosInstance.patch(`/procedimientos/${procedimiento.id}/`, {
                peritos_asignados: peritosSeleccionados.map(perito => perito.id),
                estado: estado  // Actualizar el estado a "Asignado Peritos"
            });

            console.log('Respuesta del servidor:', response.data);

            onUpdate({ ...procedimiento, peritos_asignados: peritosSeleccionados, estado: estado });
            onClose();
        } catch (error) {
            console.error('Error al actualizar los peritos asignados:', error.response?.data || error);
        }
    };

    const filteredPeritos = availablePeritos.filter(perito =>
        `${perito.nombre} ${perito.apellidos}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>Editar Peritos Asignados</h2>
                <input
                    type="text"
                    placeholder="Buscar peritos..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <ul className="peritos-list">
                    {filteredPeritos.slice(0, 5).map(perito => (
                        <li key={perito.id} onClick={() => handleAddPerito(perito)}>
                            {perito.nombre} {perito.apellidos} ({perito.especialidad_pericial.nombre})
                        </li>
                    ))}
                </ul>
                <h3>Peritos Seleccionados:</h3>
                <ul className="selected-peritos">
                    {peritosSeleccionados.map(perito => (
                        <li key={perito.id}>
                            {perito.nombre} {perito.apellidos} ({perito.especialidad_pericial.nombre})
                            <button onClick={() => handleRemovePerito(perito.id)}>Eliminar</button>
                        </li>
                    ))}
                </ul>
                <div className="popup-actions">
                    <button onClick={handleSave}>Guardar</button>
                    <button onClick={onClose}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default EditarPeritosPopup;