import React, { useState } from 'react';
import { ConfigProvider, Button } from 'antd';
import axiosInstance from '../services/axiosConfig';
import './EditarNotasPopup.css';

const EditarNotasPopup = ({ onClose, procedimiento, onUpdate }) => {
    const [notas, setNotas] = useState(procedimiento.notas || '');

    const handleInputChange = (e) => {
        setNotas(e.target.value);
    };

    const handleSubmit = async () => {
        const payload = {
            notas: notas, // Solo envía el campo 'notas'
        };

        try {
            const response = await axiosInstance.patch(`procedimientos/${procedimiento.id}/`, payload);
            if (response.status === 200) {
                onUpdate({ ...procedimiento, notas });
                onClose();
            }
        } catch (error) {
            console.error('Error al actualizar las notas:', error.response?.data || error);
        }
    };

    return (
        <ConfigProvider theme={{ token: { colorPrimary: '#1890ff', borderRadiusLG: 8 } }}>
            <div className="popup-overlay">
                <div className="popup-content">
                    <h2>Editar Notas</h2>
                    <form>
                        <label>
                            Notas:
                            <textarea
                                name="notas"
                                value={notas}
                                onChange={handleInputChange}
                            />
                        </label>
                        <div className="popup-actions">
                            <Button onClick={onClose} className="cancel-button">
                                Cancelar
                            </Button>
                            <Button type="primary" onClick={handleSubmit}>
                                Guardar Cambios
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default EditarNotasPopup;