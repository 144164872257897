import React, { useState, useEffect } from 'react';
import { ConfigProvider, Switch, Button } from 'antd';
import axiosInstance from '../services/axiosConfig';
import './EditarProcedimientoPopup.css';

const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date)) return '';
    return date.toISOString().slice(0, 16);
};

const EditarProcedimientoPopup = ({ onClose, procedimiento, onUpdate }) => {
    const [juzgados, setJuzgados] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [searchTermJuzgado, setSearchTermJuzgado] = useState('');
    const [searchTermEspecialidad, setSearchTermEspecialidad] = useState('');

    const [showJuzgadoField, setShowJuzgadoField] = useState(false);
    const [showEspecialidadField, setShowEspecialidadField] = useState(false);

    const [updatedProcedimiento, setUpdatedProcedimiento] = useState({
        ...procedimiento,
        fecha_asignacion: formatDateForInput(procedimiento.fecha_asignacion),
        fecha_ratificacion: formatDateForInput(procedimiento.fecha_ratificacion),
    });

    useEffect(() => {
        const fetchJuzgados = async () => {
            try {
                const response = await axiosInstance.get('juzgados/', {
                    params: { search: searchTermJuzgado }
                });
                setJuzgados(response.data);
            } catch (error) {
                console.error('Error al cargar juzgados:', error);
            }
        };

        if (searchTermJuzgado) {
            fetchJuzgados();
        } else {
            setJuzgados([]);
        }
    }, [searchTermJuzgado]);

    useEffect(() => {
        const fetchEspecialidades = async () => {
            try {
                const response = await axiosInstance.get('especialidades/', {
                    params: { search: searchTermEspecialidad }
                });
                setEspecialidades(response.data);
            } catch (error) {
                console.error('Error al cargar especialidades:', error);
            }
        };

        if (searchTermEspecialidad) {
            fetchEspecialidades();
        } else {
            setEspecialidades([]);
        }
    }, [searchTermEspecialidad]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedProcedimiento({
            ...updatedProcedimiento,
            [name]: value
        });
    };

    const handleSearchChangeJuzgado = (e) => {
        setSearchTermJuzgado(e.target.value);
    };

    const handleSearchChangeEspecialidad = (e) => {
        setSearchTermEspecialidad(e.target.value);
    };

    const handleSelectJuzgado = (juzgado) => {
        setUpdatedProcedimiento({ ...updatedProcedimiento, juzgado: juzgado.id });
        setSearchTermJuzgado(juzgado.nombre);
        setJuzgados([]);
    };

    const handleSelectEspecialidad = (especialidad) => {
        setUpdatedProcedimiento({ ...updatedProcedimiento, especialidad: especialidad.id });
        setSearchTermEspecialidad(especialidad.nombre);
        setEspecialidades([]);
    };

    const handleSwitchChange = (checked, name) => {
        setUpdatedProcedimiento({
            ...updatedProcedimiento,
            [name]: checked ? 'si' : 'no'
        });
    };

    const handleSubmit = async () => {
        const payload = {};

        // Compara cada campo modificado con el valor original y añade al payload solo si ha cambiado
        if (updatedProcedimiento.numero_procedimiento !== procedimiento.numero_procedimiento) {
            payload.numero_procedimiento = updatedProcedimiento.numero_procedimiento;
        }
        if (updatedProcedimiento.juzgado !== procedimiento.juzgado) {
            payload.juzgado = updatedProcedimiento.juzgado;
        }
        if (updatedProcedimiento.especialidad !== procedimiento.especialidad) {
            payload.especialidad = updatedProcedimiento.especialidad;
        }
        if (updatedProcedimiento.fecha_asignacion !== procedimiento.fecha_asignacion) {
            payload.fecha_asignacion = updatedProcedimiento.fecha_asignacion || null;
        }
        if (updatedProcedimiento.fecha_ratificacion !== procedimiento.fecha_ratificacion) {
            payload.fecha_ratificacion = updatedProcedimiento.fecha_ratificacion || null;
        }
        if (updatedProcedimiento.numero_episodio_iml !== procedimiento.numero_episodio_iml) {
            payload.numero_episodio_iml = updatedProcedimiento.numero_episodio_iml;
        }
        if (updatedProcedimiento.control_calidad !== procedimiento.control_calidad) {
            payload.control_calidad = updatedProcedimiento.control_calidad;
        }
        if (updatedProcedimiento.numero_miembros_evaluados !== procedimiento.numero_miembros_evaluados) {
            payload.numero_miembros_evaluados = updatedProcedimiento.numero_miembros_evaluados;
        }
        if (updatedProcedimiento.incomparecencias !== procedimiento.incomparecencias) {
            payload.incomparecencias = updatedProcedimiento.incomparecencias;
        }
        if (updatedProcedimiento.ratificacion_solicitada !== procedimiento.ratificacion_solicitada) {
            payload.ratificacion_solicitada = updatedProcedimiento.ratificacion_solicitada;
        }
        if (updatedProcedimiento.informe_ratificado !== procedimiento.informe_ratificado) {
            payload.informe_ratificado = updatedProcedimiento.informe_ratificado;
        }

        // Solo procede si hay cambios
        if (Object.keys(payload).length > 0) {
            try {
                const response = await axiosInstance.patch(`procedimientos/${procedimiento.id}/`, payload);
                if (response.status === 200) {
                    onUpdate({ ...procedimiento, ...payload });
                    onClose();
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error al actualizar el procedimiento:', error.response?.data || error);
            }
        } else {
            onClose(); // Cerrar si no hay cambios
        }
    };

    return (
        <ConfigProvider theme={{ token: { colorPrimary: '#1890ff', borderRadiusLG: 8 } }}>
            <div className="popup-overlay">
                <div className="popup-content">
                    <h2>Editar Procedimiento</h2>
                    <div className="button-container">
                        <Button onClick={() => setShowJuzgadoField(!showJuzgadoField)}>
                            Cambiar Juzgado
                        </Button>
                        <Button onClick={() => setShowEspecialidadField(!showEspecialidadField)}>
                            Cambiar Especialidad
                        </Button>
                    </div>
                    <form>
                        <label>
                            Número de Procedimiento:
                            <input
                                type="text"
                                name="numero_procedimiento"
                                value={updatedProcedimiento.numero_procedimiento}
                                onChange={handleInputChange}
                            />
                        </label>
                        {showJuzgadoField && (
                            <label>
                                Juzgado:
                                <input
                                    type="text"
                                    placeholder="Buscar juzgado..."
                                    value={searchTermJuzgado}
                                    onChange={handleSearchChangeJuzgado}
                                />
                                {juzgados.length > 0 && (
                                    <ul className="dropdown-menu">
                                        {juzgados.slice(0, 4).map((juzgado) => (
                                            <li key={juzgado.id} onClick={() => handleSelectJuzgado(juzgado)}>
                                                {juzgado.nombre}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </label>
                        )}
                        {showEspecialidadField && (
                            <label>
                                Especialidad:
                                <input
                                    type="text"
                                    placeholder="Buscar especialidad..."
                                    value={searchTermEspecialidad}
                                    onChange={handleSearchChangeEspecialidad}
                                />
                                {especialidades.length > 0 && (
                                    <ul className="dropdown-menu">
                                        {especialidades.slice(0, 4).map((especialidad) => (
                                            <li key={especialidad.id}
                                                onClick={() => handleSelectEspecialidad(especialidad)}>
                                                {especialidad.nombre}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </label>
                        )}
                        <label>
                            Fecha de Asignación:
                            <input
                                type="datetime-local"
                                name="fecha_asignacion"
                                value={updatedProcedimiento.fecha_asignacion}
                                onChange={handleInputChange}
                            />
                        </label>
                        <label>
                            Fecha de Ratificación:
                            <input
                                type="datetime-local"
                                name="fecha_ratificacion"
                                value={updatedProcedimiento.fecha_ratificacion}
                                onChange={handleInputChange}
                            />
                        </label>
                        <label>
                            Número de Episodio IML:
                            <input
                                type="text"
                                name="numero_episodio_iml"
                                value={updatedProcedimiento.numero_episodio_iml}
                                onChange={handleInputChange}
                            />
                        </label>
                        <label>
                            Control de Calidad:
                            <select
                                name="control_calidad"
                                value={updatedProcedimiento.control_calidad}
                                onChange={handleInputChange}
                            >
                                <option value="pendiente">Pendiente</option>
                                <option value="aprobado">Aprobado</option>
                                <option value="suspendido">Suspendido</option>
                            </select>
                        </label>

                        <label>
                            Número de Miembros Evaluados:
                            <input
                                type="number"
                                name="numero_miembros_evaluados"
                                value={updatedProcedimiento.numero_miembros_evaluados}
                                onChange={handleInputChange}
                            />
                        </label>
                        <label>
                            Incomparecencias:
                            <Switch
                                checked={updatedProcedimiento.incomparecencias === 'si'}
                                onChange={(checked) => handleSwitchChange(checked, 'incomparecencias')}
                            />
                        </label>
                        <label>
                            Ratificación Solicitada:
                            <Switch
                                checked={updatedProcedimiento.ratificacion_solicitada === 'si'}
                                onChange={(checked) => handleSwitchChange(checked, 'ratificacion_solicitada')}
                            />
                        </label>
                        <label>
                            Informe Ratificado:
                            <Switch
                                checked={updatedProcedimiento.informe_ratificado === 'si'}
                                onChange={(checked) => handleSwitchChange(checked, 'informe_ratificado')}
                            />
                        </label>
                        <div className="popup-actions">
                            <Button onClick={onClose} className="cancel-button">
                                Cancelar
                            </Button>
                            <Button type="primary" onClick={handleSubmit}>
                                Guardar Cambios
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default EditarProcedimientoPopup;