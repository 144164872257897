import React, { useState, useEffect } from 'react';
import { Dropdown, ConfigProvider, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axiosInstance from '../services/axiosConfig';
import 'antd/dist/reset.css';
import './NuevoProcedimientoPopup.css';

const NuevoProcedimientoPopup = ({ onClose, onSubmit }) => {
    const [juzgados, setJuzgados] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [searchTermJuzgado, setSearchTermJuzgado] = useState('');
    const [searchTermEspecialidad, setSearchTermEspecialidad] = useState('');

    const [loading, setLoading] = useState(false);

    const [nuevoProcedimiento, setNuevoProcedimiento] = useState({
        juzgado: '',
        numero_procedimiento: '',
        especialidad: '',
        numero_episodio_iml: '',
        notas: ''
    });

    useEffect(() => {
        const fetchJuzgados = async () => {
            try {
                const response = await axiosInstance.get('juzgados/', {
                    params: { search: searchTermJuzgado }
                });
                setJuzgados(response.data);
            } catch (error) {
                console.error('Error al cargar juzgados:', error);
            }
        };

        if (searchTermJuzgado) {
            fetchJuzgados();
        } else {
            setJuzgados([]);
        }
    }, [searchTermJuzgado]);

    useEffect(() => {
        const fetchEspecialidades = async () => {
            try {
                const response = await axiosInstance.get('especialidades/', {
                    params: { search: searchTermEspecialidad }
                });
                setEspecialidades(response.data);
            } catch (error) {
                console.error('Error al cargar especialidades:', error);
            }
        };

        if (searchTermEspecialidad) {
            fetchEspecialidades();
        } else {
            setEspecialidades([]);
        }
    }, [searchTermEspecialidad]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNuevoProcedimiento({
            ...nuevoProcedimiento,
            [name]: value
        });
    };

    const handleSearchChangeJuzgado = (e) => {
        setSearchTermJuzgado(e.target.value);
    };

    const handleSearchChangeEspecialidad = (e) => {
        setSearchTermEspecialidad(e.target.value);
    };

    const handleSelectJuzgado = (juzgado) => {
        setNuevoProcedimiento({ ...nuevoProcedimiento, juzgado: juzgado.id });
        setSearchTermJuzgado(juzgado.nombre);
        setTimeout(() => setJuzgados([]), 100);
    };

    const handleSelectEspecialidad = (especialidad) => {
        setNuevoProcedimiento({ ...nuevoProcedimiento, especialidad: especialidad.id });
        setSearchTermEspecialidad(especialidad.nombre);
        setTimeout(() => setEspecialidades([]), 100);
    };

    const handleSubmit = async () => {
        try {
            const response = await axiosInstance.post('procedimientos/', nuevoProcedimiento);
            if (response.status === 201) {
                onSubmit(nuevoProcedimiento);
                window.location.reload();// Aquí se invocará `handleSubmitNuevoProcedimiento` en Platform.js
            }
        } catch (error) {
            console.error('Error al crear el procedimiento:', error);
        }
    };

    const handleSubmitAndContinue = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('procedimientos/', nuevoProcedimiento);
            if (response.status === 201) {
                setNuevoProcedimiento({
                    juzgado: '',
                    numero_procedimiento: '',
                    especialidad: '',
                    numero_episodio_iml: '',
                    notas: ''
                });
                setSearchTermJuzgado('');
                setSearchTermEspecialidad('');
            }
        } catch (error) {
            console.error('Error al crear el procedimiento:', error);
        } finally {
            setLoading(false);
        }
    };

    const items = [
        {
            label: 'Confirmar y solicitar otro',
            key: '1',
            onClick: handleSubmitAndContinue,
        },
    ];

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#1890ff', // Primary color
                    colorPrimaryHover: '#40a9ff', // Hover color
                    borderRadiusLG: 8, // Large border radius
                },
            }}
        >
            <div className="popup-overlay">
                <div className="popup-content">
                    <h2>Nuevo Procedimiento</h2>
                    <form>
                        <label>
                            Juzgado:
                            <input
                                type="text"
                                placeholder="Buscar juzgado..."
                                value={searchTermJuzgado}
                                onChange={handleSearchChangeJuzgado}
                            />
                            {juzgados.length > 0 && (
                                <ul className="dropdown-menu">
                                    {juzgados.slice(0, 4).map(juzgado => (
                                        <li key={juzgado.id} onClick={() => handleSelectJuzgado(juzgado)}>
                                            {juzgado.nombre}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </label>
                        <label>
                            Número de Procedimiento:
                            <input type="text" name="numero_procedimiento" value={nuevoProcedimiento.numero_procedimiento} onChange={handleInputChange} required />
                        </label>
                        <label>
                            Especialidad:
                            <input
                                type="text"
                                placeholder="Buscar especialidad..."
                                value={searchTermEspecialidad}
                                onChange={handleSearchChangeEspecialidad}
                            />
                            {especialidades.length > 0 && (
                                <ul className="dropdown-menu">
                                    {especialidades.slice(0, 4).map(especialidad => (
                                        <li key={especialidad.id} onClick={() => handleSelectEspecialidad(especialidad)}>
                                            {especialidad.nombre}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </label>
                        <label>
                            Número de Episodio IML:
                            <input type="text" name="numero_episodio_iml" value={nuevoProcedimiento.numero_episodio_iml} onChange={handleInputChange} />
                        </label>
                        <label>
                            Notas:
                            <textarea name="notas" value={nuevoProcedimiento.notas} onChange={handleInputChange}></textarea>
                        </label>
                        <div className="popup-actions">
                            <button type="button" className="cancel-button" onClick={onClose}>Cancelar</button>
                            <Dropdown.Button
                                className="create-button"
                                icon={<DownOutlined />}
                                loading={loading}
                                menu={{
                                    items,
                                }}
                                onClick={handleSubmit}
                            >
                                Crear solicitud
                            </Dropdown.Button>
                        </div>
                    </form>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default NuevoProcedimientoPopup;