// src/components/Header.js

import React from 'react';
import './Header.css';  // Referencia al nuevo archivo CSS

const Header = () => {
    return (
        <header className="plataforma-header modern-header">
            <div className="logo-container">
                <img src={require('../assets/images/logo.svg').default} alt="Company Logo" className="header-logo" />
            </div>
            <div className="header-content">
                <div className="company-info">
                    <h1 className="company-name"><strong>AF Peritaciones</strong></h1>
                    <p className="company-slogan">Plataforma Integral de Gestión de Peritaciones Judiciales Forenses</p>
                </div>
                <nav className="menu-horizontal">
                    <a href="/plataforma">Mis Procedimientos</a>
                    <a href="/resumen-estadistico">Resumen Estadístico</a>
                    <a href="/" onClick={() => {
                        localStorage.removeItem('authToken');
                        window.location.href = '/';
                    }}>Cerrar Sesión</a>
                </nav>
            </div>
        </header>
    );
};

export default Header;